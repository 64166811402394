import { render, staticRenderFns } from "./DeptTreeSelectDialogWithParent.vue?vue&type=template&id=62645e45&scoped=true&"
import script from "./DeptTreeSelectDialogWithParent.vue?vue&type=script&lang=js&"
export * from "./DeptTreeSelectDialogWithParent.vue?vue&type=script&lang=js&"
import style0 from "./DeptTreeSelectDialogWithParent.vue?vue&type=style&index=0&id=62645e45&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62645e45",
  null
  
)

export default component.exports
<template>
  <EditDialog width="50%" :isShow.sync="isDialogShow" @submit="onDeptSubmit" submitBtnText="确认">
    <div slot="title" class="title">选择部门</div>
    <div slot="content" class="content deptContent" v-loading="deptTreeLoading">
      <el-tree
        ref="tree"
        default-expand-all
        show-checkbox
        node-key="id"
        :data="deptTree"
        :default-checked-keys="selectedTreeData"
        :props="{ children: 'children', label: 'deptName' }"
        :expand-on-click-node="false"
        :check-on-click-node="true"
        :check-strictly="true"
        :render-content="renderContent"
        accordion
        @check="handleCheck"
      >
      </el-tree>
    </div>
  </EditDialog>
</template>

<script>
export default {
  name: 'DeptTreeSelectDialog',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    isDeptTreeShow: {
      default: false,
      type: Boolean,
    },
    selectedTreeData: {
      default: () => {
        return []
      },
      type: Array,
    },
  },
  data() {
    return {
      isDialogShow: false,
      deptTreeLoading: false,
      deptTree: [],
      renderContent: function (h, { node, data, store }) {
        let addElement = arguments[0]
        if (data.level == 1) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconcompany iconColor' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 2) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconcangku iconColor' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 3) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconconnections iconColor' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        } else if (data.level == 4) {
          return addElement('span', [
            addElement('i', { class: 'iconfont iconCustomermanagement iconColor' }),
            addElement('span', { class: 'deptName' }, arguments[1].node.label),
          ])
        }
      },
    }
  },
  created() {
    this.getDeptTree()
  },
  watch: {
    isDeptTreeShow: {
      immediate: true,
      handler: function (newVal) {
        this.isDialogShow = newVal
      },
    },
    isDialogShow: {
      immediate: true,
      handler(newVal) {
        this.$emit('update:isDeptTreeShow', newVal)
      },
    },
  },
  methods: {
    handleCheck(node, checkedStatus) {
      // 如果当前节点被选中，自动选中所有父节点
      if (checkedStatus.checkedKeys.includes(node.id)) {
        let parent = this.findParentNode(node.parentId);
        while (parent) {
          this.$refs.tree.setChecked(parent.id, true, false);
          parent = this.findParentNode(parent.parentId);
        }
      }
    },
    // 新增方法：自动勾选父节点
    checkParentNodes(keys) {
      keys.forEach(key => {
        let node = this.$refs.tree.getNode(key);
        if (node && node.parent) {
          this.$refs.tree.setChecked(node.parent, true, false);
          this.checkParentNodes([node.parent.data.id]);
        }
      });
    },
    
    getDeptTree() {
      this.deptTreeLoading = true
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptTree = res.data
          console.log('this.deptTree', this.deptTree)
          this.deptTreeLoading = false
          
          // 在树加载完成后自动勾选父节点
          this.$nextTick(() => {
            if (this.selectedTreeData.length > 0) {
              this.checkParentNodes(this.selectedTreeData);
            }
          });
        })
        .catch(err => {
          console.log(err)
        })
    },
    onDeptSubmit() {
      const checkedNodes = this.$refs.tree.getCheckedNodes();
      
      // 处理部门名称，添加父级部门名称
      const processedNodes = checkedNodes.map(node => {
        const path = this.getDeptPath(node);
        return {
          ...node,
          deptName: path.join(' / ')
        };
      });

      this.$emit('getDeptList', processedNodes);
      this.isDialogShow = false
    },
    // 获取部门路径
    getDeptPath(node) {
      const path = [];
      let current = node;
      
      while (current) {
        path.unshift(current.deptName);
        current = this.findParentNode(current.parentId);
      }
      
      return path;
    },
    // 根据parentId查找父节点
    findParentNode(parentId) {
      const findNode = (nodes) => {
        for (const node of nodes) {
          if (node.id === parentId) {
            return node;
          }
          if (node.children && node.children.length) {
            const found = findNode(node.children);
            if (found) return found;
          }
        }
        return null;
      };
      
      return findNode(this.deptTree);
    },
  },
}
</script>

<style scoped lang="scss">
/deep/.deptContent {
  min-height: 350px;
  max-height: 500px;
  overflow: auto;
}
/deep/.deptName {
  font-size: 14px;
}

/deep/.iconColor {
  color: #399ffb;
}
</style>
